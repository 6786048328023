
$main-nav-color:  #333;
$bronze-color: #eec989;
$dark-pink-color: #772349;
$product-color: #333;
$background-section-color: #f5f5f5;

body {
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  font-size: 14px;
  min-width: 320px;
  position: relative;
  margin: 0;
  line-height: 1.42857;
  -webkit-font-smoothing: antialised; }
body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: red; }
body input:required:valid,
body textarea:required:valid {
	color: green; }

.hidden {
	display: none; }
.md-show {
  display: none; }
.row {
  overflow: hidden; }
.container {
  max-width: 1145px;
  margin: 0 auto;
  padding: 0 20px; }
.col-md-4 {
  width: 33.33333333%; }


.btn {
  max-width: 210px;
  width: 100%;
  height: 52px;
  border: 0;
  color: #fff;
  font-size: 14px;
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 4px;
  cursor: pointer;

  &.btn-pink {
    background: $dark-pink-color; } }

h1,
h2 {
  text-transform: uppercase;
  margin: 0; }

a {
  text-decoration: none; }

ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    float: left; } }

.main-wrapper {
  max-width: 100%;
  margin: 0 auto; }

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block; }

.navbar-header {
  //margin: 13px 140px
  margin: 13px 0;
  position: relative; }
nav {
  overflow: hidden; }
.icon-menu-wrapper {
  display: none; }
.icon-menu {
  position: absolute;
  top: 50%;
  left: 0;
  width: 30px;
  height: 2px;
  margin: -1px 0 0 0;
  background: #000; }
.icon-menu:before, .icon-menu:after {
  position: absolute;
  top: -9px;
  left: 0;
  width: 100%;
  height: 2px;
  margin: -1px 0 0 0;
  content: '';
  -webkit-transition: all .1s linear;
  transition: all .1s linear;
  background: inherit; }
.icon-menu:after {
  top: auto;
  bottom: -10px; }
.nav-open {
  overflow-y: hidden; }
.nav-open .icon-menu:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #000; }
.nav-open .icon-menu:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background: #000; }
.nav-open .icon-menu {
  background: transparent; }

.navbar-contacts {
  text-transform: uppercase;
  letter-spacing: 1.15px;
  float: right;
  margin-top: 35px;
  ul {
    li {
      font-family: "Oxygen", sans-serif;
      color: #a7a7a7;
      border-right: 2px solid #a7a7a7;
      line-height: 14px;
      padding: 0 10px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        border-right: none;
        padding-right: 0; } } } }

.navbar-brand,
.logo-bottom {
  max-width: 190px;
  min-height: 155px;
  display: inline-block; }
.navbar-right {
  float: right; }

.navbar-main {
  float: right;
  clear: both;
  margin-top: 50px;
  form {
    float: left;
    margin-right: 25px;
    button[type=submit] {
      border: 0;
      background: 0;
      cursor: pointer;
      padding: 0; }
    i {
      font-size: 16px; } }
  ul {
    float: left;
    line-height: 19px;
    li {
      &:last-child {
        a {
          padding-right: 0; } }
      a {
        font-family: "Oxygen", sans-serif;
        text-decoration: none;
        text-transform: uppercase;
        color: $main-nav-color;
        font-size: 16px;
        padding: 0 32px; } } } }

.slider {
  position: relative;
  .slider-caption {
    font-family: 'Alegreya Sans', sans-serif;
    max-width: 465px;
    position: absolute;
    left: 140px;
    top: 29%;
    h1 {
      font-size: 72px;
      color: #fff;
      font-weight: normal;
      line-height: 80px;
      text-shadow: 0 1px 3px rgba(150, 150, 150, 0.25);
      span {
        font-size: 37px;
        text-transform: none;
        color: $bronze-color; } }
    button {
      margin-top: 15px; } } }

.s-product {
  text-align: center;
  .product-wrapper {
    overflow: hidden;
    background: $background-section-color;
    .row {
      display: table;
      width: 100%; } }
  .col-md-4 {
    display: table-cell;
    vertical-align: top;
    position: relative;
    &:before {
      border-width: 15px !important;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; } }
  .col-first {
    &:before {
      border-right-color: #f5f5f5 !important;
      margin-top: -15px !important;
      right: 0;
      top: 50%; } }
  .col-third {
    &:before {
      border-bottom-color: #f5f5f5 !important;
      margin-left: -15px !important;
      bottom: 0;
      left: 50%; } }
  .col-fifth {
    &:before {
      border-left-color: #f5f5f5 !important;
      margin-top: -15px !important;
      left: 0;
      top: 50%; } }

  h2 {
    font-family: 'Alegreya Sans', sans-serif;
    font-weight: 300;
    font-size: 36px;
    text-align: center;
    letter-spacing: 6.7px;
    line-height: 130px; }
  img {
    max-height: 650px; }
  .product-item {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%); }
  .product-title {

    h2 {
      font-family: 'Alegreya Sans', sans-serif;
      color: $product-color;
      font-size: 30px;
      line-height: 40px;
      text-shadow: 0 1px 3px rgba(150, 150, 150, 0.25);
      span {
        color: $bronze-color;
        font-size: 50px;
        font-weight: 300; } } }
  .hr {
    width: 165px;
    height: 1px;
    background: #dadada;
    margin: 30px auto 0; }

  .product-content {
    max-width: 380px;
    font-family: "Oxygen", sans-serif;
    font-size: 12px;
    margin: 33px auto 0;
    text-align: center;
    padding: 0 10px; }

  a {
    font-family: 'Raleway', sans-serif;
    color: $dark-pink-color;
    text-transform: uppercase;
    font-size: 11px;
    border-bottom: 1px solid $dark-pink-color;
    padding: 0 5px 2px;
    margin-top: 30px;
    display: inline-block;
    letter-spacing: 1.5px;
    &:hover {
      border-bottom: 0;
      margin-bottom: 1px; } } }


.s-banner {
  background: $dark-pink-color;
  overflow: hidden;
  text-align: center;
  padding: 30px 0;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  span {
    color: #fff;
    margin-right: 100px;
    font-family: "Oxygen", sans-serif;
    white-space: nowrap;
    line-height: 30px;
    &:last-child {
      margin-right: 0; } }
  a {
    color: #fff; }
  i {
    color: #fff;
    font-size: 16px;
    margin-right: 15px; }
  ul {
    display: inline-block;
    li {
      color: #fff;
      font-family: "Oxygen", sans-serif; } }
  li:first-child {
    margin-right: 80px; }
  li:nth-child(2) {
    margin-right: 80px; }
  li:last-child {
    ul {
      float: right;
      li {
        margin-right: 10px; } } } }

#map {
  width: 100%;
  height: 400px; }

.s-about {
  background: $background-section-color;
  overflow: hidden;
  .about-left {
    float: left;
    img {
      max-height: 724px;
      max-width: 500px; } }
  .about-right {
    text-align: center;
    h2 {
      font-size: 60px;
      font-family: 'Alegreya Sans', sans-serif;
      color: $bronze-color;
      padding: 120px 0 15px;
      font-weight: normal;
      text-shadow: 0 1px 3px rgba(150, 150, 150, 0.25); } } }

footer {
  padding: 60px 0;
  text-align: center;
  font-family: "Oxygen", sans-serif;
  .nav-bottom-wrapper {
    position: relative; }
  .nav-bottom {
    display: inline-block;
    li {
      float: none;
      display: inline-block;
      vertical-align: middle;
      a {
        font-family: "Oxygen", sans-serif;
        color: $main-nav-color;
        margin-right: 50px;
        text-transform: uppercase; }
      &:nth-child(3) {
        margin-right: 50px; }
      &:nth-child(4) {
        margin-right: 13px; } } }
  .hr {
    width: 260px;
    height: 1px;
    background: #dadada;
    margin: 30px auto 0; }
  .social {
    margin-top: 20px;
    text-align: center;
    ul {
      display: inline-block;
      li {
        margin-right: 10px;
        &:first-child {
          i {
            padding: 7px 12px; } }
        i {
          border: 1px solid #d4d4d4;
          padding: 7px 10px;
          border-radius: 20px;
          color: #d4d4d4; } } } }
  .copyright {
    color: #a4a4a4;
    margin-top: 40px;
    letter-spacing: 1px; } }
